import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';
import parseHTML from '../lib/parse-html';
import formToQuerystring from '../lib/form-to-querystring';

defineCustomElement('gents-product-overview', {
	controller: class extends BaseController {
		bind() {
			this.on('change', (e, changed) => {
				if (window.innerWidth < 768) {
					// Only auto-update with the order select & products per page
					if (changed.name === 'order' || changed.name === 'pp') {
						this.update();
					}
				} else {
					this.update();
				}
			});
		}

		update() {
			if (this.abortController) {
				this.abortController.abort();
			}

			const querystring = formToQuerystring(this.el.querySelector('form'));
			const url = `${window.location.pathname}?${querystring}`;

			if ('AbortController' in window) {
				this.abortController = new AbortController();
			}

			fetch(url, {
				method: 'GET',
				credentials: 'same-origin',
				headers: {
					Accept: 'text/html',
				},
				signal: this.abortController ? this.abortController.signal : undefined,
			}).then((res) => {
				if (res.ok) {
					return res.text();
				}

				throw new Error('no-products');
			}).then((res) => {
				const { content } = parseHTML(res, this.el.tagName);
				this.el.innerHTML = content.innerHTML;
				window.history.pushState({}, document.title, url);
			}).catch(() => {});
		}
	},
});
