import {
	defineCustomElement, BaseController, Template,
} from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-tooltip', {
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve(true);
		}

		init() {
			this.templates = {};
			this.templates.tooltip = new Template('.tpl-tooltip', this.el);
		}

		bind() {
			this.on('mouseenter', () => {
				this.show();
			});

			this.on('mouseleave', () => {
				this.hide();
			});
		}

		render() {
			if (this.tooltip) {
				return;
			}

			this.tooltip = this.templates.tooltip.render().firstElementChild;
			this.el.appendChild(this.tooltip);
			this.hide();
		}

		show() {
			if (!this.tooltip) {
				this.render();
			}

			this.tooltip.removeAttribute('hidden');
		}

		hide() {
			if (!this.tooltip) {
				this.render();
			}

			this.tooltip.setAttribute('hidden', 'hidden');
		}
	},
});
