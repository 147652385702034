export default function updateCart(data) {
	const authenticatedData = (() => {
		if (data) {
			data.append(window.csrfTokenName, window.csrfTokenValue);
			return data;
		}

		const authentication = new FormData();
		authentication.append(window.csrfTokenName, window.csrfTokenValue);
		return authentication;
	})();

	return fetch('/actions/commerce/cart/update-cart', {
		method: 'POST',
		body: authenticatedData,
		credentials: 'same-origin',
		headers: {
			Accept: 'application/json',
		},
	}).then((res) => {
		if (res.ok) {
			return res.json();
		}

		throw new Error('update-cart-failed');
	}).then(({ cart }) => cart);
}
