import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-select-all', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.selectAll = this.el.querySelector('.js-select-all-all');
			this.elements.inputs = Array.from(this.el.querySelectorAll('[type=checkbox]:not(.js-select-all-all)'));
		}

		bind() {
			if (this.elements.selectAll) {
				const listenForReset = (e, input) => {
					if (!input.checked) {
						this.el.parentNode.classList.remove('has-all-selected');
						this.elements.selectAll.checked = false;
					}
				};

				this.on('change', (e, selectAll) => {
					if (selectAll.checked) {
						this.selectAll();
						this.on('change [type=checkbox]', listenForReset);
					} else {
						this.deselectAll();
						this.off('change [type=checkbox]', listenForReset);
					}
				}, this.elements.selectAll);
			}
		}

		selectAll() {
			this.el.parentNode.classList.add('has-all-selected');

			this.elements.inputs.forEach((input) => {
				input.checked = true;
			});
		}

		deselectAll() {
			this.el.parentNode.classList.remove('has-all-selected');

			this.elements.inputs.forEach((input) => {
				input.checked = false;
			});
		}
	},
});
