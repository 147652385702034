import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-search-overlay', {
	attributes: [
		{ attribute: 'hidden', type: 'boolean' },
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.input = this.el.querySelector('input[type=search]');
		}

		bind() {
			this.on('click .js-search-overlay-toggle', (e) => {
				e.preventDefault();
				e.stopPropagation();

				this.hidden = !this.hidden;

				if (!this.hidden) {
					this.elements.input.focus();
				}
			}, document.body);
		}
	},
});
