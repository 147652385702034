import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-amount-input', {
	controller: class extends BaseController {
		get value() {
			return parseInt(this.elements.input.getAttribute('value'), 10);
		}

		set value(to) {
			this.elements.input.setAttribute('value', this.validate(to));

			this.render();

			this.emit('amount-input:change');
		}

		init() {
			this.elements = {};
			this.elements.input = this.el.querySelector('.js-amount-input-number');
			this.elements.decrement = this.el.querySelector('.js-amount-input-decrement');
			this.elements.increment = this.el.querySelector('.js-amount-input-increment');

			if (this.elements.input.hasAttribute('min')) {
				this.minimum = parseInt(this.elements.input.getAttribute('min'), 10);
			}

			if (this.elements.input.hasAttribute('max')) {
				this.maximum = parseInt(this.elements.input.getAttribute('max'), 10);
			}
		}

		validate(number) {
			let validated = number;

			if (Number.isNaN(validated)) {
				return (typeof this.minimum === 'number') ? this.minimum : 0;
			}

			if (typeof this.minimum === 'number') {
				validated = Math.max(this.minimum, validated);
			}

			if (typeof this.maximum === 'number') {
				validated = Math.min(this.maximum, validated);
			}

			return validated;
		}

		bind() {
			this.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
				this.value -= 1;
			}, this.elements.decrement);

			this.on('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
				this.value += 1;
			}, this.elements.increment);

			this.on('change', () => {
				this.render();
			}, this.elements.input);

			this.on('keyup', () => {
				this.render();
			}, this.elements.input);

			this.on('paste', () => {
				this.render();
			}, this.elements.input);

			this.on('blur', () => {
				this.value = this.validate(this.value);
				this.render();
			}, this.elements.input);
		}

		render() {
			const value = parseInt(this.elements.input.value, 10);

			this.elements.decrement.disabled = value === this.minimum;
			this.elements.increment.disabled = value === this.maximum;
		}
	},
});
