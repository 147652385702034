export default function getCart() {
	const authentication = new FormData();
	authentication.append(window.csrfTokenName, window.csrfTokenValue);

	return fetch('/actions/commerce/cart/get-cart', {
		method: 'POST',
		body: authentication,
		credentials: 'same-origin',
		headers: {
			Accept: 'application/json',
		},
	}).then((res) => {
		if (res.ok) {
			return res.json();
		}
	}).then(({ cart }) => cart);
}
