import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';
import getCart from '../lib/craft-commerce/get-cart';

defineCustomElement('gents-cart-count', {
	attributes: [
		{ attribute: 'count', type: 'number' },
	],
	controller: class extends BaseController {
		resolve() { return Promise.resolve(true); }

		init() {
			return getCart().then(({ totalQty }) => {
				this.count = totalQty || 0;
			}).catch(() => {});
		}

		bind() {
			this.on('cart:change', ({ detail: { cart: { totalQty } } }) => {
				this.count = totalQty || 0;
				this.render();
			}, window);
		}

		render() {
			if (this.count > 0) {
				this.el.classList.add('is-showing');
			} else {
				this.el.classList.remove('is-showing');
			}
		}
	},
});
