import { defineCustomElement, ajaxForm, Template } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-newsletter-form', {
	attributes: ['jsonp'],
	controller: class extends ajaxForm.controller {
		get action() {
			try {
				// Mailchimp allows you to post JSON to another endpoint
				// but we cannot change it directly in the HTML
				// as that would break progressive enhancement
				return new URL(this.elements.form.action.replace('subscribe/post', 'subscribe/post-json'));
			} catch (e) {
				return new URL(this.elements.form.action, window.location.origin);
			}
		}

		init() {
			super.init();

			this.templates = this.templates || {};
			this.templates.success = new Template('.tpl-newsletter-form-success', this.el);
		}

		onSuccess() {
			const target = this.el.querySelector('.js-newsletter-form-success-target');
			target.innerHTML = '';
			target.appendChild(this.templates.success.render());
		}
	},
});
