import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-header-toggle', {
	type: 'attribute',
	on: HTMLAnchorElement,
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.target = document.body.querySelector('.js-header-toggle-target');
		}

		bind() {
			this.on('click', (e) => {
				e.preventDefault();
				this.elements.target.classList.toggle('is-open');

				Object.assign(document.body.style, {
					overflow: this.elements.target.classList.contains('is-open') ? 'hidden' : '',
				});
			});
		}
	},
});
