export default (function parseHTML() {
	const parser = new DOMParser();

	return function parse(html, selector = null) {
		const parsed = parser.parseFromString(html, 'text/html');

		const { title } = parsed;
		let { body: content } = parsed;

		if (selector) {
			content = content.querySelector(selector);

			if (!content) {
				throw new Error('not-found');
			}
		}

		return { title, content };
	};
}());
