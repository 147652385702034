import { defineCustomElement, BaseController, Template } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-add-to-cart-tooltip', {
	controller: class extends BaseController {
		resolve() { return Promise.resolve(true); }

		init() {
			this.templates = {};
			this.templates.tooltip = new Template('.tpl-tooltip', this.el);
		}

		bind() {
			this.on('cart:change', ({ detail: { cart, action, submitted } }) => {
				if (action === 'add') {
					const quantity = submitted.qty;
					const { snapshot: product } = Object.values(cart.lineItems).find(({ purchasableId }) => purchasableId === submitted.purchasableId);

					const title = (() => {
						if (product.title === product.product.title) {
							return product.title;
						}

						return `${product.product.title} - ${product.title}`;
					})();

					this.show(`${quantity} × ${title}`);
				}
			}, window);

			this.on('click .js-tooltip-close', () => {
				this.hide();
			});
		}

		show(content = '') {
			this.hide();

			const tooltip = this.templates.tooltip.render({
				'.js-tooltip-content': {
					textContent: content,
				},
			});

			this.el.appendChild(tooltip);

			if (window.innerWidth >= 768) {
				window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			}

			this.closeTimeout = setTimeout(() => {
				this.hide();
			}, 30000);
		}

		hide() {
			if (this.closeTimeout) {
				clearTimeout(this.closeTimeout);
			}

			const children = Array.from(this.el.querySelectorAll('*:not(template)'));

			children.forEach((child) => {
				child.parentNode.removeChild(child);
			});
		}
	},
});
