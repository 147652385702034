import { defineCustomElement, BaseController, Template } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-slideshow', {
	attributes: [
		{ attribute: 'current', type: 'number' },
		{ attribute: 'auto', type: 'boolean' },
		{ attribute: 'loop', type: 'boolean' },
	],
	controller: class extends BaseController {
		currentChangedCallback(from, to) {
			if (from === to) {
				return;
			}

			if (to < 0) {
				if (this.loop) {
					this.current = this.elements.slides.length - 1;
				} else {
					this.current = 0;
				}
			}

			if (to >= this.elements.slides.length) {
				if (this.loop) {
					this.current = 0;
				} else {
					this.current = this.elements.slides.length - 1;
				}
			}

			this.update();
		}

		init() {
			this.elements = {};
			this.elements.slides = Array.from(this.el.querySelectorAll('.js-slideshow-slide'));

			this.templates = {};
			this.templates.dots = new Template('.tpl-slideshow-dots', this.el);
			this.templates.dot = new Template('.tpl-slideshow-dot', this.el);

			if (!this.current) {
				this.current = 0;
			}
		}

		bind() {
			this.on('click .js-slideshow-dot', (e, button) => {
				e.preventDefault();
				e.stopPropagation();
				this.current = parseInt(button.textContent, 10);
			}, this.el, { capture: true });

			const swipeSettings = {
				treshold: 40,
				maxDuration: 600,
				deviation: 20,
			};

			let swipe;
			let wasSwipe = false;

			const getCoordinates = (e) => {
				if (e.changedTouches && e.changedTouches.length > 0) {
					const { clientX: x, clientY: y } = e.changedTouches[0];
					return { x, y };
				}

				const { clientX: x, clientY: y } = e;
				return { x, y };
			};

			const upHandler = (e) => {
				e.stopPropagation();

				const timestamp = (new Date()).getTime();
				const { x, y } = getCoordinates(e);

				if (timestamp - swipe.timestamp > swipeSettings.maxDuration) {
					return;
				}

				const diffX = x - swipe.x;
				const diffY = y - swipe.y;

				if (Math.abs(diffX) >= swipeSettings.treshold) {
					if (Math.abs(diffY) <= swipeSettings.deviation) {
						wasSwipe = true;

						if (diffX < 0) {
							this.current = this.current + 1;
						} else {
							this.current = this.current - 1;
						}
					}
				}
			};

			const downHandler = (e) => {
				const timestamp = (new Date()).getTime();
				const { x, y } = getCoordinates(e);

				swipe = { x, y, timestamp };

				this.once('mouseup', upHandler, this.el, { passive: true });
				this.once('touchend', upHandler, this.el, { passive: true });
			};

			this.on('mousedown .js-slideshow-slide', downHandler, this.el, { passive: true });
			this.on('touchstart .js-slideshow-slide', downHandler, this.el, { passive: true });

			const reset = () => {
				swipe = undefined;
				wasSwipe = false;
			};

			this.on('mouseup', reset, document.body, { passive: true });
			this.on('touchend', reset, document.body, { passive: true });

			this.on('click', (e) => {
				if (!wasSwipe && e.target.closest('a') === null) {
					e.preventDefault();

					const { left, width } = this.el.getBoundingClientRect();
					const { clientX: x } = e;

					if ((x - left) / width < 0.25) {
						this.current = this.current - 1;
					} else {
						this.current = this.current + 1;
					}
				}

				wasSwipe = false;
			});

			if (this.auto) {
				this.autoplay = setTimeout(() => {
					this.current += 1;
				}, 4500);
			}
		}

		destroy() {
			if (this.autoplay) {
				clearTimeout(this.autoplay);
			}
		}

		render() {
			if (!this.elements.dots && this.elements.slides.length > 1) {
				const dots = this.templates.dots.render();

				this.elements.slides.forEach((slide, index) => {
					const dot = this.templates.dot.render({
						button: { textContent: index },
					});

					dots.firstElementChild.appendChild(dot);

					return dot;
				});

				this.el.appendChild(dots);

				this.elements.dots = this.el.querySelectorAll('.js-slideshow-dot');
			}

			this.update();
		}

		update() {
			if (this.autoplay) {
				clearTimeout(this.autoplay);
				this.autoplay = null;
			}

			this.elements.slides.forEach((slide, index) => {
				slide.classList.toggle('is-active', index === this.current);
			});

			if (this.elements.dots) {
				this.elements.dots.forEach((button, index) => {
					button.classList.toggle('is-active', index === this.current);
				});
			}

			if (this.auto && !this.autoplay) {
				this.autoplay = setTimeout(() => {
					this.current += 1;
				}, 3000);
			}
		}
	},
});
