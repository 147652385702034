import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-read-more', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
		}

		render() {
			if (this.offsetHeight < 160) {
				return Promise.reject();
			}

			this.close();

			this.elements.button = document.createElement('button');
			this.elements.button.textContent = 'Lees meer…';
			this.elements.button.className = 'read-more__button';
			this.el.appendChild(this.elements.button);

			return Promise.resolve();
		}

		bind() {
			this.elements.button.addEventListener('click', () => {
				this.el.removeChild(this.elements.button);
				this.elements.button = undefined;
				this.open();
			});
		}

		open() {
			Object.assign(this.el.style, {
				maxHeight: '',
				overflow: '',
			});
		}

		close() {
			Object.assign(this.el.style, {
				maxHeight: '120px',
				overflow: 'hidden',
			});
		}
	},
});
