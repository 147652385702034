import { defineCustomElement, BaseController, Template } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-filter-toggle', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.button = document.body.querySelector('.js-filter-toggle-button');
			this.elements.target = document.body.querySelector('.js-filter-toggle-target');

			this.labels = {};
			this.labels.show = this.elements.button.textContent.trim();
			this.labels.hide = (new Template('.tpl-hide-label')).render().textContent.trim();
		}

		bind() {
			this.on('click .js-filter-toggle-button', (e) => {
				e.preventDefault();

				if (this.elements.target.style.display === 'block') {
					Object.assign(this.elements.target.style, { display: '' });
					this.elements.button.textContent = this.labels.show;
				} else {
					Object.assign(this.elements.target.style, { display: 'block' });
					this.elements.button.textContent = this.labels.hide;
				}
			});
		}
	},
});
