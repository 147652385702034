import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-cookie-bar', {
	controller: class extends BaseController {
		resolve() {
			if (document.cookie.includes('cookie_consent')) {
				setTimeout(() => this.hide(), 1);
				return Promise.reject();
			}

			return Promise.resolve();
		}

		bind() {
			this.on('click .js-cookie-bar-accept', () => {
				const domain = window.location.hostname.split('.').splice(-2).join('.');
				document.cookie = `cookie_consent=true;domain=${domain};path=/;max-age=31536000;secure`;
				this.hide();
			});
		}

		hide() {
			this.el.parentNode.removeChild(this.el);
		}
	},
});
