import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

import updateCart from '../lib/craft-commerce/update-cart';
import formatCurrency from '../lib/craft-commerce/format-currency';

defineCustomElement('gents-shipping-preview', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.form = this.el.querySelector('form');
			this.elements.shipping = this.el.querySelector('.js-shipping-preview-shipping');
			this.elements.total = this.el.querySelector('.js-shipping-preview-total');
		}

		bind() {
			this.on('change', () => {
				this.update();
			});
		}

		render() {
			const checked = this.el.querySelector('input[type=radio]:checked');

			if (!checked) {
				this.el.querySelector('input[type=radio]:first-child').click();
				this.update();
			}
		}

		update() {
			const data = new FormData(this.elements.form);

			return updateCart(data).then((cart) => {
				if (cart.adjustments && cart.adjustments.shipping) {
					this.elements.shipping.textContent = formatCurrency(cart.adjustments.shipping[0].amount);
				} else {
					this.elements.shipping.textContent = 'Gratis';
				}

				this.elements.total.textContent = formatCurrency(cart.totalPrice);
			});
		}
	},
});
