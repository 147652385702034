import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-toggle-visibility', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.toggle = this.el.querySelector('.js-toggle-visibility-toggle');
			this.elements.target = this.el.querySelector('.js-toggle-visibility-target');
		}

		bind() {
			this.on('change', (e, toggle) => {
				if (toggle.checked) {
					this.elements.target.setAttribute('hidden', 'hidden');
				} else {
					this.elements.target.removeAttribute('hidden');
				}
			}, this.elements.toggle);
		}
	},
});
