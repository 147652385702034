import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';

import updateCart from '../lib/craft-commerce/update-cart';

defineCustomElement('gents-add-to-cart', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.form = this.el.querySelector('form');
		}

		bind() {
			this.on('submit', (e) => {
				e.preventDefault();
				e.stopPropagation();

				const data = new FormData(this.elements.form);
				const submitted = {
					qty: this.elements.form.querySelector('[name=qty]').value,
					purchasableId: this.elements.form.querySelector('[name=purchasableId]').value,
				};

				updateCart(data).then((cart) => {
					this.emit('cart:change', { cart, action: 'add', submitted });
				});
			}, this.elements.form);
		}
	},
});
