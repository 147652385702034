import { defineCustomElement, BaseController, Template } from '@pieterbeulque/custom-elements-helpers';

import updateCart from '../lib/craft-commerce/update-cart';
import formatCurrency from '../lib/craft-commerce/format-currency';

defineCustomElement('gents-cart', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.form = this.el.querySelector('form');
			this.elements.items = Array.from(this.el.querySelectorAll('.js-cart-item'));
			this.elements.total = this.el.querySelector('.js-cart-total-price');
			this.elements.checkout = this.el.querySelector('.js-cart-checkout');

			this.templates = {};
			this.templates.empty = new Template('.tpl-cart-empty', this.el);
			this.templates.error = new Template('.tpl-cart-error', this.el);
		}

		bind() {
			this.on('change', () => {
				this.update();
			});

			this.on('amount-input:change', () => {
				this.update();
			});
		}

		update() {
			const data = new FormData(this.elements.form);

			return updateCart(data).then((cart) => {
				if (this.elements.errors) {
					this.elements.errors.innerHTML = '';
				}

				if (cart.errors) {
					if (!this.elements.errors) {
						this.elements.errors = document.createElement('div');
						this.el.prepend(this.elements.errors);
					}

					this.elements.checkout.setAttribute('disabled', '');

					Object.values(cart.errors).forEach((errorGroup) => {
						errorGroup.forEach((error) => {
							this.elements.errors.appendChild(this.templates.error.render({
								'.js-cart-error': error,
							}));
						});
					});
				} else {
					this.emit('cart:change', { cart, action: 'updated' });

					this.elements.items.forEach((lineItem) => {
						if (cart.lineItems[lineItem.dataset.lineItem]) {
							const { subtotal } = cart.lineItems[lineItem.dataset.lineItem];
							const subtotalEl = lineItem.querySelector('.js-cart-subtotal');
							subtotalEl.textContent = formatCurrency(subtotal);
						} else {
							lineItem.parentNode.removeChild(lineItem);
						}
					});

					this.elements.items = Array.from(this.el.querySelectorAll('.js-cart-item'));

					if (this.elements.items.length === 0) {
						this.el.parentNode.replaceChild(this.templates.empty.render(), this.el);
					}

					this.elements.total.textContent = formatCurrency(cart.totalPrice);
				}
			});
		}
	},
});
