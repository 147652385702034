import {
	defineCustomElement, ajaxForm, parseHTML, renderNodes,
} from '@pieterbeulque/custom-elements-helpers';

defineCustomElement('gents-ajax-form', {
	attributes: [...ajaxForm.attributes],
	controller: class extends ajaxForm.controller {
		onSuccess(html) {
			const { content } = parseHTML(html, this.el.tagName);
			renderNodes(content, this.el);
		}
	},
});
