export default function formToQuerystring(form) {
	return Array.from(form.querySelectorAll('[name]'), (i) => {
		if (!i.value) {
			return '';
		}

		if ((i.type === 'radio' || i.type === 'checkbox') && !i.checked) {
			return '';
		}

		return [i.name, i.value];
	}).filter((p) => !!p).map(([key, value]) => `${key}=${value}`).sort()
		.join('&');
}
