import { defineCustomElement, BaseController } from '@pieterbeulque/custom-elements-helpers';


defineCustomElement('gents-accordion', {
	attributes: [
		{ attribute: 'toggle', type: 'boolean' },
	],
	controller: class extends BaseController {
		resolve() { return Promise.resolve(true); }

		bind() {
			this.on('click button', (e, button) => {
				e.preventDefault();
				e.stopPropagation();

				const content = button.parentNode.nextElementSibling;
				const isHidden = content.hidden;

				if (this.toggle === true) {
					this.hideAll();
				}

				if (isHidden) {
					// Show content
					button.setAttribute('aria-expanded', true);
					content.removeAttribute('hidden');

					if (button.parentNode.id) {
						window.location.hash = button.parentNode.id;
					}
				} else {
					// Hide content
					button.setAttribute('aria-expanded', false);
					content.setAttribute('hidden', '');
					window.history.pushState({}, document.title, `${window.location.pathname}${window.location.search}`);
				}
			});

			this.on('popstate', (e) => {
				this.render();
			}, window);
		}

		render() {
			this.hideAll();

			if (window.location.hash) {
				const show = this.el.querySelector(`${window.location.hash}`);
				const button = show.querySelector('button');
				const content = show.nextElementSibling;
				button.setAttribute('aria-expanded', true);
				content.removeAttribute('hidden');
			}
		}

		hideAll() {
			const buttons = Array.from(this.el.querySelectorAll('button'));

			buttons.forEach((button) => {
				if (!button.hasAttribute('disabled')) {
					button.setAttribute('aria-expanded', false);
					button.parentNode.nextElementSibling.setAttribute('hidden', '');
				}
			});
		}
	},
});
